import lozad                                                from 'lozad';
import Swiper, { Navigation, Pagination, Autoplay, Lazy }   from 'swiper';
import SlideUpDown                                          from 'vue-slide-up-down';
import mixitup                                              from "mixitup"
import DatePicker                                           from 'vue2-datepicker';
import dayjs                                                from 'dayjs';

Swiper.use([Navigation, Pagination, Autoplay, Lazy]);

// Lozad
window.lozadObserver = lozad('.lozad', {
    loaded: el => {
        const cropPos = el.getAttribute('data-crop-pos');
        if (cropPos) {
            const img = el.getElementsByTagName('img');
            if (img) img[0].style.objectPosition = cropPos;
        }
        el.classList.add('loaded');
    }
});

// Import our CSS
import '../css/app-base.pcss';
import '../css/app-components.pcss';
import '../css/app-utilities.pcss';
import 'swiper/swiper-bundle.css';
/*import 'vue2-datepicker/index.css';*/

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    const { default: axios } = await import( /* webpackChunkName: "axios" */ 'axios');
    const VueCookies = await import( /* webpackChunkName: "vue-cookies" */ 'vue-cookies');
    const {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} = await import( /* webpackChunkName: "body-scroll-lock" */ 'body-scroll-lock');
    const {default: vueSmoothScroll} = await import(/* webpackChunkName: "vue2-smooth-scroll" */ 'vue2-smooth-scroll');

    Vue.component('slide-up-down', SlideUpDown);
    Vue.use(VueCookies);
    Vue.use(vueSmoothScroll, {
        offset: -40,
    });
    
    window.axios = axios;
    
    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        delimiters: ['${', '}'],
        
        components: {
            DatePicker,
            "frequently-asked-questions": () => import(/* webpackChunkName: "frequently-asked-questions" */ "../vue/FrequentlyAskedQuestions.vue"),
            // 'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
        },
        
        data: {
            // Cookie consent
            showStatusBar: true,
            mobileMenuActive: false,
            searchResultsActiveTab: 'pages',

            eventActiveItemId: '',
            eventTime: null,
            pickedEventType: '',
            eventOnRequest: false,
            // Modals
            modals: []
        },
    
        created() {
            // window.addEventListener('scroll', this.onWindowScroll);
        },
    
        mounted() {
            window.lozadObserver.observe();
    
            // Init formie
            if (window.Formie) {
                window.Formie.initForms();
            }

            this.initBlockPartners();
            this.removePosterAndPlayVideo();
            this.eventFilter();
        },
    
        beforeDestroy() {
            // window.removeEventListener('scroll', this.onWindowScroll);
        },
        
        methods: {
            onWindowScroll() {},
    
            closeStatusBar(slug) {
                VueCookies.set(`status-${ slug }`, 'hidden', '7d');
                this.showStatusBar = false;
            },

            toggleMobileMenu() {
                this.mobileMenuActive = !this.mobileMenuActive;

                const targetElement = document.querySelector('.site-content');
                this.mobileMenuActive ? disableBodyScroll(targetElement) : enableBodyScroll(targetElement);
            },

            initBlockPartners() {
                const blockEl = document.querySelectorAll('.block-partners');
                for (let block of blockEl) {
                    let swipeEl = (block.getElementsByClassName('swiper-container').length) ? block.getElementsByClassName('swiper-container')[0] : false;
                    if (swipeEl) {
                        new Swiper(swipeEl, {
                            // Disable preloading of all images
                            preloadImages: false,
                            // Enable lazy loading
                            lazy: true,
                            loop: true,
                            slidesPerView: 1,
                            centeredSlides: true,
                            speed: 1000,
                            autoplay: {
                                delay: 2000,
                                disableOnInteraction: false
                            },
                            breakpoints: {
                                // when window width is >= 768px
                                768: {
                                    slidesPerView: 3
                                },
                                1024: {
                                    slidesPerView: 4,
                                    centeredSlides: false
                                }
                            }
                        });
                    }
                }
            },

            removePosterAndPlayVideo() {
                const embedVideos = document.querySelectorAll('.block-video-embed__inner');
                const heroVideos = document.querySelectorAll('.block-hero__video');

                const videos = [...embedVideos, ...heroVideos];

                for (let video of videos) {
                    video.querySelector('.poster').addEventListener('click', function(){
                        const conts = video.childNodes
                        const le = conts.length
                        let ifr = null;

                        for(let i = 0; i<le; i++){
                            if(conts[i].nodeName === "IFRAME") ifr = conts[i];
                        }

                        video.classList.add('player');

                        let videoSrc = ifr.getAttribute("src")
                        let symbol = videoSrc.indexOf("?") > -1 ? "&" : "?";
                        ifr.setAttribute("src", videoSrc+ symbol + "autoplay=1");
                    })
                }
            },

            toggleEventAccordion(id) {
                this.eventActiveItemId = this.eventActiveItemId === id ? '' : id;
            },

            eventFilter(){
                const containerEl = document.querySelector('.events__inner-wrapper');
                if (containerEl) {
                    this.eventMixer = mixitup(containerEl);
                }
            },

            filterStartDate() {

                this.eventOnRequest = false;

                const pickedDate = dayjs(this.eventTime).format('YYYYMMDD');

                function filterTestResult(testResult, target) {
                    const eventDate = Number(target.dom.el.getAttribute('data-date'));
                    const onRequest = target.dom.el.getAttribute('data-onrequest');
                    if (eventDate < pickedDate || onRequest == "true") {
                        testResult = false;
                    }
                    return testResult;
                }

                mixitup.Mixer.registerFilter('testResultEvaluateHideShow', 'date', filterTestResult);
                this.eventMixer.filter(this.eventMixer.getState().activeFilter);
            },

            filterEventType() {
                this.eventMixer.filter(this.pickedEventType);
            },

            filterEventOnRequest() {

                this.eventTime = null;

                const eventOnRequest = this.eventOnRequest;

                function filterTestResult(testResult, target) {
                    const onRequest = target.dom.el.getAttribute('data-onrequest');
                    if (eventOnRequest && onRequest == "false") {
                        testResult = false;
                    }
                    return testResult;
                }

                mixitup.Mixer.registerFilter('testResultEvaluateHideShow', 'date', filterTestResult);
                this.eventMixer.filter(this.eventMixer.getState().activeFilter);
            },

            resetTypeFilter () {
                this.pickedEventType = '';
                this.eventMixer.filter('all');
            },

            resetStartDateFilter () {
                this.eventTime = null;

                const pickedDate = dayjs().format('YYYYMMDD');

                function filterTestResult(testResult, target) {
                    const eventDate = Number(target.dom.el.getAttribute('data-date'));
                    //if (eventDate < pickedDate) {
                    //    testResult = false;
                    //}
                    return testResult;
                }

                mixitup.Mixer.registerFilter('testResultEvaluateHideShow', 'date', filterTestResult);
                this.eventMixer.filter(this.eventMixer.getState().activeFilter);
            },

            resetOnRequestFilter () {
                this.eventOnRequest = false;
                this.filterEventOnRequest();
            },

            resetAllFilters () {
                this.resetOnRequestFilter();
                this.resetTypeFilter();
                this.resetStartDateFilter();
            },

            openModal(id) {
                if(this.modals.includes(id)) {
                    return;
                }

                this.modals.push(id);
            },
            closeModal(id) {
                const index = this.modals.indexOf(id);
                this.modals.splice(index, 1);
            },
        },
    
        computed: {
            selectedDateTag(){
                return this.eventTime ? dayjs(this.eventTime).format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY');
            },

            selectedEventTypeTag (){
                return this.pickedEventType.replace(".", "")
            }
        }
    });
    
    return vm;
};

// Execute async function
main().then( (vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
